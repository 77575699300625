@font-face {
    font-weight: 100;
    font-family: Roboto;
    font-style: normal;
    src: local('Roboto Thin'), local('Roboto-Thin'),
        url('./Roboto-Thin.woff2') format('woff2'),
        url('./Roboto-Thin.woff') format('woff');
}

/* @font-face { */

/*    font-weight: 300; */

/*    font-family: Roboto; */

/*    font-style: normal; */

/*    src: local('Roboto Light'), local('Roboto-Light'), */

/*        url('./Roboto-Light.woff2') format('woff2'), */

/*        url('./Roboto-Light.woff') format('woff'); */

/* } */

@font-face {
    font-weight: 400;
    font-family: Roboto;
    font-style: normal;
    src: local('Roboto Regular'), local('Roboto-Regular'),
        url('./Roboto-Regular.woff2') format('woff2'),
        url('./Roboto-Regular.woff') format('woff');
}

@font-face {
    font-weight: 500;
    font-family: Roboto;
    font-style: normal;
    src: local('Roboto Medium'), local('Roboto-Medium'),
        url('./Roboto-Medium.woff2') format('woff2'),
        url('./Roboto-Medium.woff') format('woff');
}

@font-face {
    font-weight: 700;
    font-family: Roboto;
    font-style: normal;
    src: local('Roboto Bold'), local('Roboto-Bold'),
        url('./Roboto-Bold.woff2') format('woff2'),
        url('./Roboto-Bold.woff') format('woff');
}

/* @font-face { */

/*    font-weight: 900; */

/*    font-family: Roboto; */

/*    font-style: normal; */

/*    src: local('Roboto Black'), local('Roboto-Black'), */

/*        url('./Roboto-Black.woff2') format('woff2'), */

/*        url('./Roboto-Black.woff') format('woff'); */

/* } */
